import { PageProps } from 'gatsby';
import React from 'react';
import { LayoutProvider } from '../../components/layout';
import { SEOOldSection } from '../../components/sections';
import { stackStyled } from '../../theme';

const MainContainer = stackStyled('section', {
  height: '100%',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
});

export const RetailerPromotions: React.FC<PageProps> = (props: PageProps) => {
  // HOOKS

  // STATE

  // HANLDERS && FUNCTIONS

  // RENDER

  return (
    <LayoutProvider seo locationSearch={props.location.search}>
      <MainContainer>
        <SEOOldSection
          title={'Retailers Promotions'}
          subTitle={'Check back for weekly deals from our retailers!'}
          buttonText={'Explore all products'}
          buttonHref={'/products'}
        />
      </MainContainer>
    </LayoutProvider>
  );
};

export default RetailerPromotions;
